import React from "react";
import useGSAP from "./useGSAP";

const CustomCursor = () => {
  const cursorRef = useGSAP((gsap, cursor) => {
    const moveCursor = (e) => {
      gsap.to(cursor, {
        duration: 1,
        x: e.clientX,
        y: e.clientY,
        // ease: "power3",
        ease: "power3.out",
      });
    };

    window.addEventListener("mousemove", moveCursor);

    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return <div ref={cursorRef} className="cursor"></div>;
};

export default CustomCursor;
