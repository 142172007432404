import "./App.css";
import CustomCursor from "./components/CustomCursor";
import Design from "./components/Design";
import Hero from "./components/Hero";
import Main from "./components/Main";

function App() {
  return (
    <>
      <div className="smooth-content">
        <CustomCursor />

        <Main />

        <Hero />

        <Design />
      </div>
    </>
  );
}

export default App;
