import React from "react";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

export default function Main() {
  gsap.registerPlugin(ScrollTrigger);
  const intro = useRef();
  useGSAP(() => {
    gsap.from("#title-1", {
      opacity: 0,
      y: "+=30",
      stagger: 0.5,
    });
    gsap.to("#title-1", {
      opacity: 0,
      y: "-=30",
      delay: 1,
      stagger: 0.5,
    });
    gsap.to("#intro-slider", {
      xPercent: "-100",
      duration: 1.3,
      delay: 1.5,
    });
  }, intro);

  useGSAP(() => {
    gsap.from(["#title1", "#title2"], {
      delay: 2.2,
      opacity: 0,
      duration: 0.5,
      stagger: 0.5,
      y: "-40",
    });
    gsap.from("#circle", {
      y: "-50",
      opacity: 0,
      delay: 2.2,
      duration: 1,
    });
    gsap.from(["#nav1", "#nav2", "#nav3", "#nav4"], {
      delay: 2.2,
      opacity: 0,
      y: "-20",
      duration: 0.5,
      stagger: 0.5,
    });
  });

  useGSAP(() => {
    gsap.to("#circle", {
      scrollTrigger: {
        trigger: "#circle",
        toggleActions: "restart none none none",
      },
      x: -1250,
      delay: 2.2,
      rotate: 180,
      duration: 3,
    });
  });

  return (
    <div className="relative" ref={intro}>
      <div
        id="intro-slider"
        className="h-screen w-full bg-[#153d4a] flex justify-center items-center absolute"
      >
        <div className="flex justify-center items-center w-[300px] h-[300px]  rounded-full border-[5px] border-textMain ">
          <h2 id="title-1" className="text-3xl font-bold text-textMain">
            <i class="ri-bard-fill"></i> Amanda Brada{" "}
          </h2>
        </div>
      </div>
      <div
        id="main"
        className="h-screen w-full bg-backgroundMain flex justify-center  items-center"
      >
        <div className=" w-1/6 flex flex-col text-[#8AA99A] text-1xl font-bold tracking-wide pl-5 z-20">
          <h3
            id="nav1"
            className="text-3xl cursor-pointer hover:text-[#c48c70]"
          >
            <i class="ri-bard-fill"></i>
          </h3>
          <h3 id="nav2" className="cursor-pointer hover:text-[#c48c70]">
            About
          </h3>
          <h3 id="nav3" className="cursor-pointer hover:text-[#c48c70]">
            Project
          </h3>
          <h3 id="nav4" className="cursor-pointer hover:text-[#c48c70]">
            Contact
          </h3>
        </div>
        <div className="w-4/6 flex flex-col justify-center  items-center">
          <h1
            id="title1"
            className="font-bold text-9xl font-playFair tracking-widest uppercase text-textMain font-outline-4"
          >
            Amanda
          </h1>
          <h1
            id="title2"
            className="font-bold text-9xl font-playFair tracking-widest uppercase text-textMain"
          >
            Brada
          </h1>
        </div>
        <div
          id="circle"
          className="w-[150px] mt-16 ml-24 bg-textMain h-72 rounded-l-full overflow-hidden"
        ></div>
      </div>
    </div>
  );
}
