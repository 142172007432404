import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React from "react";

export default function Design() {
  gsap.registerPlugin(ScrollTrigger);
  useGSAP(() => {
    gsap.from("#design", {
      opacity: 0,
      y: 60,
      scrollTrigger: {
        trigger: "#design",
      },
    });
  });

  useGSAP(() => {
    gsap.to("#circle1", {
      x: 250,
      duration: 3,
      scrollTrigger: {
        trigger: "#circle1",
        start: "top center",
      },
    });
    gsap.to("#circle2", {
      x: -250,
      duration: 3,
      scrollTrigger: {
        trigger: "#circle2",
        start: "top center",
      },
    });
  });

  useGSAP(() => {
    gsap.to("#marquee", {
      xPercent: -100,
      repeat: -1,
      duration: 100,
      ease: "power3.out",
    });
  });
  return (
    <>
      <div className=" py-36 w-full bg-backgroundMain flex flex-col items-center justify-center">
        <div className="overflow-hidden">
          <img
            src="https://www.amandabraga.com/img/2x/amanda.png"
            alt="designer"
            className="grayscale rounded hover:scale-110 hover:filter-none duration-300 ease-in"
          />
        </div>
        <h1
          id="design"
          className="text-9xl font-playFair text-textMain z-10 w-2/3 text-center -mt-24"
        >
          Design in her language
        </h1>
      </div>
      <div className=" w-full bg-backgroundMain flex flex-col">
        <div
          className="w-full text-6xl font-bold uppercase text-[#8AA99A] font-playFair tracking-widest relative overflow-hidden whitespace-nowrap
            "
        >
          <div className="flex flex-row flex-auto w-fit">
            <div id="marquee" className="w-full h-16">
              Creative Consultant Creative Consultant Creative Consultant
              Creative Consultant Creative Consultant Creative Consultant
              Creative Consultant{" "}
            </div>
          </div>
        </div>

        <div className="  flex justify-between items-center my-24">
          <div
            id="circle1"
            className="w-[600px] h-[600px] rounded-full overflow-hidden"
          >
            <img
              src="https://www.amandabraga.com/img/2x/amanda_circle_over.png"
              alt="photoa"
              className=" grayscale w-auto hover:filter-none hover:scale-110 duration-500 ease-in"
            />
          </div>
          <div
            id="circle2"
            className="w-[350px] h-[350px] bg-[#8AA99A] rounded-full  z-10 text-center py-14 px-12 text-3xl text-backgroundMain font-playFair"
          >
            Years later, she now has the opportunity to bring back those
            memories by immersing herself
          </div>
        </div>
      </div>
    </>
  );
}
