import React from "react";

export default function Hero() {
  return (
    <>
      <div
        id="box"
        className="w-full h-screen flex justify-center items-center bg-backgroundMain"
      >
        <div className="w-2/3 text-[#8AA99A] font-bold text-5xl font-playFair text-center tracking-wider">
          Amanda is a Brazilian fashion designer based in Miami. From an early
          age she was interested in the arts and manual tasks and her favorite
          activity was always drawing, which she loves to do until the present
          days. For Amanda, few things are more pleasurable than seeing an idea
          become material and enter people's lives, creating bonds between those
          who create and those who wear it.
        </div>
      </div>
    </>
  );
}
