import { useEffect, useRef } from "react";
import { gsap } from "gsap";

const useGSAP = (callback, dependencies) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      callback(gsap, elementRef.current);
    }
  }, dependencies);

  return elementRef;
};

export default useGSAP;
